import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {Customer, Team, Ticket, TicketPriority, TicketStatus, User} from "../../../api/dto";
import {useApiCall} from "../../../api/api";
import {useRefresh} from "../../RefreshController";
import {IconDropdownButton} from "../../form/IconButtonDropdown";
import {faCircleCheck, faFlag, faUser} from "@fortawesome/free-regular-svg-icons";
import {faClock, } from "@fortawesome/free-solid-svg-icons";
import {formatTimeString} from "../../../util/formatTimeString";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/pro-solid-svg-icons";
import {TicketContextMenu} from "../TicketContextMenu";
import {faUserPen, faUserGroup, faUserHeadset} from "@fortawesome/pro-regular-svg-icons";

export const TicketButtons: FC<{ticket: Ticket, status: TicketStatus, priority: TicketPriority, allStatuses: TicketStatus[], allPriorities: TicketPriority[], customer?: Customer, tenantUsers: User[], tenantTeams: Team[]}> = (props) => {
  const refactoredStatuses = props.allStatuses.map((item) => [item.id, item.status]);
  const refactoredPriorities = props.allPriorities.map((item) => [item.id, item.priority])
  const refactoredTenantUsers = props.tenantUsers.map((item) => [item.id, item.name])
  const refactoredTenantTeams = props.tenantTeams.map((item) => [item.id, item.team_name])
  const [newStatus, setNewStatus] = useState(props.status.id)
  const [newPriority, setNewPriority] = useState(props.priority.id)
  const [newAssignee, setNewAssignee] = useState(props.ticket.assignee_id ? props.ticket.assignee_id : undefined)
  const [newTeam, setNewTeam] = useState(props.ticket.team_id ? props.ticket.team_id : undefined)
  const [newReporter, setNewReporter] = useState(props.ticket.reporter_id ? props.ticket.reporter_id : undefined)

  const reporterMap = useMemo(() => {
    let reporterMap = props.tenantUsers.map((item) => [item.id, '(Intern) ' + item.name])
    if (props.customer != null && props.customer?.users.length > 0) {
      reporterMap = [...props.customer.users.map((item) => [item.id, item.name]), ...reporterMap]
    }
    return Object.fromEntries(reporterMap)
  }, [props.tenantUsers, props.customer, props.customer?.users])

  useEffect(() => {
    setNewStatus(props.status.id)
  }, [props.status.id]);
  useEffect(() => {
    setNewPriority(props.priority.id)
  }, [props.priority.id]);
  useEffect(() => {
    setNewAssignee(props.ticket.assignee_id ? props.ticket.assignee_id : undefined)
  }, [props.ticket.assignee_id]);
  useEffect(() => {
    setNewTeam(props.ticket.team_id ? props.ticket.team_id : undefined)
  }, [props.ticket.team_id]);
  useEffect(() => {
    setNewReporter(props.ticket.reporter_id ? props.ticket.reporter_id : undefined)
  }, [props.ticket.reporter_id]);

  const {editTicket} = useApiCall()
  const reload = useRefresh()

  let totalTimeWorked = 0;
  {props.ticket.times.map((time) => {
    totalTimeWorked = totalTimeWorked + Number(time.value);
  })}

  const saveNewValues = useCallback(async (status?: string, priority?: string, assignee?: string, team?: string, reporter?: string) => {
    const deployment = await editTicket(
      props.ticket.id,
      {
        status_uuid: status,
        priority_uuid: priority,
        assignee_uuid: assignee,
        team_uuid: team,
        reporter_uuid: reporter
      })
    reload()
  }, []);

  const selectedPriority = props.allPriorities.find(p => p.id === newPriority)!
  const selectedStatus = props.allStatuses.find(p => p.id === newStatus)!
  const selectedAssignee = props.tenantUsers.find(u => u.id === newAssignee)
  const selectedTeam = props.tenantTeams.find(t => t.id === newTeam)
  const selectedReporter = props.tenantUsers.find(u => u.id === newReporter)

  const customerName = props.ticket.customer?.name ?? `${props.customer?.name ?? '??'} (verwijderd)`

  return <>
    <div
      className={"rounded border border-slate-300 dark:border-zinc-500 p-2 space-x-2 bg-white dark:bg-zinc-700 flex"}>
      <IconDropdownButton type={"secondary"} size={"sm"} text={selectedPriority.priority} icon={faFlag}
                          data={Object.fromEntries(refactoredPriorities)} onClick={async (key) => {
        setNewPriority(key)
        await saveNewValues(newStatus, key, newAssignee, newTeam, newReporter)
      }}/>
      <IconDropdownButton type={"secondary"} size={"sm"} text={selectedStatus.status} icon={faCircleCheck}
                          data={Object.fromEntries(refactoredStatuses)} onClick={async (key) => {
        setNewStatus(key)
        await saveNewValues(key, newPriority, newAssignee, newTeam, newReporter)
      }}/>
      <IconDropdownButton type={"secondary"} size={"sm"} text={selectedAssignee?.name ?? '-'} icon={faUserHeadset}
                          data={Object.fromEntries(refactoredTenantUsers)} onClick={async (key) => {
        setNewAssignee(key)
        await saveNewValues(newStatus, newPriority, key, newTeam, newReporter)
      }}/>
      <IconDropdownButton type={"secondary"} size={"sm"} text={selectedTeam?.team_name ?? '-'} icon={faUserGroup}
                          data={Object.fromEntries(refactoredTenantTeams)} onClick={async (key) => {
        setNewTeam(key)
        await saveNewValues(newStatus, newPriority, newAssignee, key, newReporter)
      }}/>
      <IconDropdownButton type={'secondary'} size={'sm'} text={reporterMap[props.ticket.reporter_id ?? '-'] ?? '-'} icon={faUserPen}
                          data={reporterMap} onClick={async (key) => {
                            setNewReporter(key)
                            await saveNewValues(newStatus, newPriority, newAssignee, newTeam, key)
      }} />
      <div className={"border-r border-slate-200 dark:border-zinc-500 -my-2"}></div>
      <div className={'flex items-center px-1 py-1 text-sm text-slate-900 dark:text-zinc-200'}>
        <span className={"mr-1"}>Klant: </span>
        {/*// @ts-ignore*/}
        <span className={`font-medium ${!props.ticket.customer ? 'text-red-500' : 'text-slate-900 dark:text-zinc-200'}`}>{customerName}</span>
      </div>
      <div className={"border-r border-slate-200 dark:border-zinc-500 -my-2"}></div>
      {props.ticket.tasks.length > 0 && <>
        <div className={'flex items-center px-1 py-1 text-sm text-slate-900 dark:text-zinc-200'}>
        <span className={"mr-1"}>Taken: </span>
          <span className={`${props.ticket.tasks.filter(t => !t.is_done).length > 0 ? 'text-amber-700 dark:text-amber-300 font-bold' : 'font-medium'}`}>{props.ticket.tasks.filter(t => t.is_done).length} / {props.ticket.tasks.length}</span>
        </div>
        <div className={"border-r border-slate-200 dark:border-zinc-500 -my-2"}></div>
      </>}
      <div className={'flex items-center px-1 py-1 text-sm text-slate-900 dark:text-zinc-200'}>
        <span className={"mr-1"}>Uren: </span>
        <span className={"font-medium"}>{formatTimeString(totalTimeWorked)}</span>
      </div>

      {/* Spacing */}
      <div className={'flex-1'}></div>
      <TicketContextMenu ticket={props.ticket} />
    </div>
    <div>
      <p
        className="text-xs italic text-slate-500 dark:text-zinc-200">{`Aangemaakt op ${props.ticket.createdAt.toLocaleDateString('nl', {
        weekday: 'long',
        day: 'numeric',
        month: 'long'
      })} door ${reporterMap[props.ticket.reporter_id ?? '-'] ?? '-'}`}</p>
    </div>
  </>
}