export type Module = 'crm'|'service_desk'|'asset_management'|'integration_wefact'|'integration_campaign_monitor'

export class Tenant {
  constructor(
    public id: string,
    public userId: string,
    public name: string,
    public slug: string,
    public color_palette: string,
    public logo: string|null,
    public created_at: Date,
    public updated_at: Date,
    public role: string,
    public permissions: string[],
    public modules: string[],
    public integrations: string[]
  ) {}

  public static fromJson(json: any): Tenant {
    return new Tenant(
      json['id'],
      json['user_id'],
      json['name'],
      json['slug'],
      json['color_palette'],
      json['logo'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
      json['role'],
      json['permissions'] ? json['permissions'] : [],
      json['modules'] ? json['modules'] : [],
      json['integrations'] ? json['integrations'] : []
    );
  }
}

export class Ticket {
  constructor(
    public id: string,
    public description: string,
    public number: string,
    public subject: string|null,
    public user_id: string,
    public user_email: string,
    public status_id: string,
    public priority_id: string,
    public customer_id: string,
    public assignee_id: string|null,
    public reporter_id: string|null,
    public team_id: string|null,
    public last_customer_reply_at: Date|null,
    public last_agent_reply_at: Date|null,
    public sla_deadline: Date|null,
    public createdAt: Date,
    public updatedAt: Date,
    public closedAt: Date|null,
    public replies: TicketReply[],
    public notes: TicketNote[],
    public attachments: TicketAttachment[],
    public status: TicketStatus|null,
    public priority: TicketPriority|null,
    public customer: Customer|null,
    public logs: TicketLog[],
    public user: User,
    public times: TicketTime[],
    public tasks: TicketTask[],
    public relatedTickets: TicketRelation[],
    public relatedToTickets: TicketRelation[],
  ) {
  }

  public static fromJson(json: any): Ticket {
    return new Ticket(
      json['id'],
      json['description'],
      json['number'],
      json['subject'],
      json['user_id'],
      json['user_email'],
      json['status_id'],
      json['priority_id'],
      json['customer_id'],
      json['assignee_id'],
      json['reporter_id'],
      json['team_id'],
      json['last_customer_reply_at'] ? new Date(json['last_customer_reply_at']) : null,
      json['last_agent_reply_at'] ? new Date(json['last_agent_reply_at']) : null,
      json['sla_deadline'] ? new Date(json['sla_deadline']) : null,
      new Date(json['created_at']),
      new Date(json['updated_at']),
      json['closed_at'] ? new Date(json['closed_at']) : null,
      json['replies'] ? json['replies'].map((r: any) => TicketReply.fromJson(r)) : [],
      json['notes'] ? json['notes'].map((n: any) => TicketNote.fromJson(n)) : [],
      json['attachments'] ? json['attachments'].map((a: any) => TicketAttachment.fromJson(a)) : [],
      json['status'] ? TicketStatus.fromJson(json['status']) : null,
      json['priority'] ? TicketPriority.fromJson(json['priority']) : null,
      json['customer'] ? Customer.fromJson(json['customer']) : null,
      json['logs'] ? json['logs'].map((l: any) => TicketLog.fromJson(l)) : [],
      json['user'] ? json['user'] : [],
      json['times'] ? json['times'].map((t: any) => TicketTime.fromJson(t)) : [],
      json['tasks'] ? json['tasks'].map((t: any) => TicketTask.fromJson(t)) : [],
      json['related_tickets'] ? json['related_tickets'].map((t: any) => TicketRelation.fromJson(t)) : [],
      json['related_to_tickets'] ? json['related_to_tickets'].map((t: any) => TicketRelation.fromJson(t)) : [],
    );
  }

  public getType(): "incident"|"service_request"|"change"|"problem" {
    if (this.number.charAt(0) === "I") return "incident"
    if (this.number.charAt(0) === "S") return "service_request"
    if (this.number.charAt(0) === "C") return "change"
    if (this.number.charAt(0) === "P") return "problem"
    return "incident"
  }
}

export class TicketRelation {
  constructor(
    public id: string,
    public ticketId: string,
    public relatedTicketId: string,
    public relation: string,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}

  public static fromJson(json: any): TicketRelation {
    return new TicketRelation(
      json['id'],
      json['ticket_id'],
      json['related_ticket_id'],
      json['related_type'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}
export class TicketHistory {
  constructor(
    public createdAt: Date,
    public updatedAt: Date,
  ) {}
}

export class TicketReply {

  constructor(
    public id: string,
    public reply: string,
    public userId: string,
    public userName: string,
    public userEmail: string,
    public createdAt: Date,
    public updatedAt: Date,
  ) {
  }

  public static fromJson(json: any): TicketReply {
    return new TicketReply(
      json['id'],
      json['reply'],
      json['user_id'],
      json['user_name'],
      json['user_email'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    );
  }
}

export class TicketNote {

  constructor(
    public id: string,
    public note: string,
    public userId: string,
    public userName: string,
    public userEmail: string,
    public createdAt: Date,
    public updatedAt: Date,
  ) {
  }

  public static fromJson(json: any): TicketNote {
    return new TicketNote(
      json['id'],
      json['note'],
      json['user_id'],
      json['user_name'],
      json['user_email'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    );
  }
}

export class TicketAttachment {
  constructor(
    public id: string,
    public userId: string|null,
    public userName: string|null,
    public fileName: string,
    public fileType: string,
    public createdAt: Date,
    public updatedAt: Date,
  ) {
  }

  public static fromJson(json: any): TicketAttachment {
    return new TicketAttachment(
      json['id'],
      json['user_id'],
      json['user_name'],
      json['file_name'],
      json['file_type'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    );
  }
}

export class TicketStatus {
  constructor(
    public id: string,
    public tenant_id: string,
    public status: string,
    public isClosed: boolean,
    public isOnHold: boolean,
    public isDefault: boolean,
    public createdAt: Date,
    public updatedAt: Date,
  ) {
  }

  public static fromJson(json: any): TicketStatus {
    return new TicketStatus(
      json['id'],
      json['tenant_id'],
      json['status'],
      json['is_closed'],
      json['is_on_hold'],
      json['is_default'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    );
  }
}

export class TicketPriority {
  constructor(
    public id: string,
    public tenant_id: string,
    public priority: string,
    public order: number,
    public createdAt: Date,
    public updatedAt: Date,
  ) {
  }

  public static fromJson(json: any): TicketPriority {
    return new TicketPriority(
      json['id'],
      json['tenant_id'],
      json['priority'],
      json['order'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    );
  }
}

export class Customer {
  constructor(
    public id: string,
    public tenant_id: string,
    public name: string,
    public contact_person: string,
    public referenceNumber: string,
    public email: string,
    public phone: string,
    public host: string,
    public address: string,
    public sla_id: string,
    public coc: string,
    public vat_id: string,
    public createdAt: Date,
    public updatedAt: Date,
    public deletedAt: Date|null,
    public users: User[],
    public tags: TagCustomer[],
  ) {
  }

  public static fromJson(json: any): Customer {
    return new Customer(
      json['id'],
      json['tenant_id'],
      json['name'],
      json['contact_person'],
      json['reference_number'],
      json['email'],
      json['phone'],
      json['url'],
      json['address'],
      json['sla_id'],
      json['coc'],
      json['vat_id'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
      json['deleted_at'] ? new Date(json['deleted_at']) : null,
      json['users'] ? json['users'].map((u: any) => User.fromJson(u)) : [],
      json['tag_customers'] ? json['tag_customers'].map((t: any) => TagCustomer.fromJson(t)) : [],
    );
  }
}

export class User {
  constructor(
    public id: string,
    public first_name: string,
    public middle_name: string,
    public last_name: string,
    public name: string,
    public can_login: boolean,
    public position: string,
    public email: string,
    public secondary_email: string,
    public address: string,
    public zipcode: string,
    public city: string,
    public phone: string,
    public remark: string,
    public customer_id: string,
    public role_id: string,
    public tag_users: TagUser[],
    public createdAt: Date,
    public updatedAt: Date,
  ) {
  }

  public static fromJson(json: any): User {
    return new User(
      json['id'],
      json['first_name'],
      json['middle_name'],
      json['last_name'],
      json['name'],
      json['can_login'],
      json['position'],
      json['email'],
      json['secondary_email'],
      json['address'],
      json['zipcode'],
      json['city'],
      json['phone'],
      json['remark'],
      json['customer_id'],
      json['role_id'],
      json['tag_users'] ? json['tag_users'].map((t: any) => TagUser.fromJson(t)) : [],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    );
  }
}

export class TicketLog {
  constructor(
    public id: string,
    public ticket_id: string,
    public user_id: string,
    public userName: string,
    public log: string,
    public createdAt: Date,
    public updatedAt: Date,
  ) {
  }

  public static fromJson(json: any): TicketLog {
    return new TicketLog(
      json['id'],
      json['ticket_id'],
      json['user_id'],
      json['user_name'],
      json['log'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    );
  }
}

export class CustomerUser {
  constructor(
    public id: string,
    public user_id: string,
    public customer_id: string,
    public role_id: string,
    public phone: string,
    public createdAt: Date,
    public updatedAt: Date,
  ) {
  }

  public static fromJson(json: any): CustomerUser {
    return new CustomerUser(
      json['id'],
      json['user_id'],
      json['customer_id'],
      json['role_id'],
      json['phone'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}

export class Sla {
  constructor(
    public id: string,
    public tenant_id: string,
    public sla_name: string,
    public createdAt: Date,
    public updatedAt: Date,
    public rules: SlaRule[],
  ) {
  }

  public static fromJson(json: any): Sla {
    return new Sla (
      json['id'],
      json['tenant_id'],
      json['sla_name'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
      json['rules'] ? json['rules'].map((r: any) => SlaRule.fromJson(r)) : [],
    )
  }
}

export class SlaRule {
  constructor(
    public id: string,
    public sla_id: string,
    public rule_type: string,
    public during_office_hours: boolean,
    public priority_id: string,
    public value: string,
    public createdAt: Date,
    public updatedAt: Date,
  ) {
  }

  public static fromJson(json: any): SlaRule {
    return new SlaRule (
      json['id'],
      json['sla_id'],
      json['rule_type'],
      json['during_office_hours'],
      json['priority_id'],
      json['value'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}

export enum SlaRuleType {
  maxResponse = 'max_response_time',
  maxSolve = 'max_resolve_time',
  uptime = 'uptime',
  supportHours = 'support_hours',
}

export class TicketTime {
  constructor(
    public id: string,
    public ticket_uuid: string,
    public category_id: string|null,
    public contract_id: string|null,
    public description: string,
    public user_uuid: string,
    public user_name: string,
    public value: number,
    public startedAt: Date,
    public createdAt: Date,
    public updatedAt: Date,
  ) {
  }

  public static fromJson(json: any): TicketTime {
    return new TicketTime (
      json['id'],
      json['ticket_id'],
      json['category_id'] ?? null,
      json['contract_id'] ?? null,
      json['description'],
      json['user_uuid'],
      json['user_name'],
      Number(json['value']),
      new Date(json['started_at']),
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}
export class TicketTask {
  constructor(
    public id: string,
    public ticket_uuid: string,
    public description: string,
    public user_uuid: string|null,
    public user_name: string|null,
    public is_done: boolean,
    public createdAt: Date,
    public updatedAt: Date,
  ) {
  }

  public static fromJson(json: any): TicketTask {
    return new TicketTask (
      json['id'],
      json['ticket_id'],
      json['description'],
      json['user_uuid'],
      json['user_name'],
      json['is_done'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}

export class DashboardReport {
  constructor(
    public totalTime: number,
    public ticketCount: number,
    public averageTime: number,
    public hoursWrittenThisWeek: number,
    public billableHoursWrittenThisWeek: number,
    public billableHoursPerDayMonday: number,
    public billableHoursPerDayTuesday: number,
    public billableHoursPerDayWednesday: number,
    public billableHoursPerDayThursday: number,
    public billableHoursPerDayFriday: number,
    public unBillableHoursPerDayMonday: number,
    public unBillableHoursPerDayTuesday: number,
    public unBillableHoursPerDayWednesday: number,
    public unBillableHoursPerDayThursday: number,
    public unBillableHoursPerDayFriday: number,
  ) {
  }

  public static fromJson(json: any): DashboardReport {
    return new DashboardReport(
      json['totalTime'],
      json['ticketCount'],
      json['averageTime'],
      json['hoursWrittenThisWeek'],
      json['billableHoursWrittenThisWeek'],
      json['billableHoursPerDay']?.['monday'] ?? 0,
      json['billableHoursPerDay']?.['tuesday'] ?? 0,
      json['billableHoursPerDay']?.['wednesday'] ?? 0,
      json['billableHoursPerDay']?.['thursday'] ?? 0,
      json['billableHoursPerDay']?.['friday'] ?? 0,
      json['nonBillableHoursPerDay']?.['monday'] ?? 0,
      json['nonBillableHoursPerDay']?.['tuesday'] ?? 0,
      json['nonBillableHoursPerDay']?.['wednesday'] ?? 0,
      json['nonBillableHoursPerDay']?.['thursday'] ?? 0,
      json['nonBillableHoursPerDay']?.['friday'] ?? 0,
  )
  }
}

export class Team {
  constructor(
    public id: string,
    public team_name: string,
    public tenant_id: string,
    public user_count: string,
    public createdAt: Date,
    public updatedAt: Date,
    public users: User[],
  ) {
  }

  public static fromJson(json: any): Team {
    return new Team(
      json['id'],
      json['team_name'],
      json['tenant_id'],
      json['user_count'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
      json['users'] ? json['users'].map((u: any) => User.fromJson(u)) : [],
    )
  }
}

export class Tag {
  constructor(
    public id: string,
    public name: string,
    public color: string,
  ) {
  }
  public static fromJson(json: any): Tag {
    return new Tag(
      json['id'],
      json['name'],
      json['color'],
    )
  }
}
export class TagCustomer {
  constructor(
    public id: string,
    public value: string,
    public tag: Tag|null,
  ) {
  }
  public static fromJson(json: any): TagCustomer {
    return new TagCustomer(
      json['id'],
      json['value'],
      json['tag'] ? Tag.fromJson(json['tag']) : null
    )
  }
}
export class TagUser {
  constructor(
    public id: string,
    public value: string,
    public tag: Tag|null,
  ) {
  }
  public static fromJson(json: any): TagUser {
    return new TagUser(
      json['id'],
      json['value'],
      json['tag'] ? Tag.fromJson(json['tag']) : null
    )
  }
}
export type dealStage = 'new'|'exploring'|'negotiating'|'quoting'|'won'|'lost'
export class Deal {
  constructor(
    public id: string,
    public customer_id: string,
    public customer: Customer,
    public name: string,
    public description: string,
    public amount: number,
    public purchase_price: number,
    public stage: dealStage,
    public user_id: string|null,
    public user: User|null,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}

  public static fromJson(json: any): Deal {
    return new Deal(
      json['id'],
      json['customer_id'],
      Customer.fromJson(json['customer']),
      json['name'],
      json['description'],
      json['amount'] / 100,
      json['purchase_price'] / 100,
      json['stage'],
      json['user_id'],
      json['user'] ? User.fromJson(json['user']) : null,
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}

export class TicketTimeCategory {
  constructor(
    public id: string,
    public name: string,
    public code: string,
    public isBillable: boolean,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}

  public static fromJson(json: any): TicketTimeCategory {
    return new TicketTimeCategory(
      json['id'],
      json['name'],
      json['code'],
      json['is_billable'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}


export class Integration {
  constructor(
    public name: string,
    public image: string,
    public integration: string,
    public relevant_modules: string[],
    public required_options: {
      key: string,
      type: string,
      value?: string,
    }[],
    public id: string,
    public configured: boolean,
  ) {}

  public static fromJson(json: any): Integration {
    return new Integration(
      json['name'],
      json['image'],
      json['integration'],
      json['relevant_modules'],
      json['options'].map((option: any) => ({
        key: option['key'],
        type: option['type'],
      })),
      json['id'],
      json['configured'],
    );
  }
}

export class CMList {
  constructor(
    public id: string,
    public name: string,
  ) {}

  public static fromJson(json: any): CMList {
    return new CMList(
      json['id'],
      json['name'],
    );
  }
}

export class CMListSubscriber {
  constructor(
    public name: string,
    public email: string,
    public joinDate: Date,
    public date: Date,
  ) {}

  public static fromJson(json: any): CMListSubscriber {
    return new CMListSubscriber(
      json['name'],
      json['email_address'],
      new Date(json['list_joined_date']),
      new Date(json['date']),
    );
  }
}

export class CMListTag {
  constructor(
    public id: string,
    public listId: string,
    public tag: Tag
  ) {}

  public static fromJson(json: any): CMListTag {
    return new CMListTag(
      json['id'],
      json['list_id'],
      Tag.fromJson(json['tag'])
    );
  }
}

export class CMListDetails {
  constructor(
    public id: string,
    public name: string,
    public tags: CMListTag[],
    public customers: Customer[],
    public subscribers: CMListSubscriber[]
  ) {}

  public static fromJson(json:any): CMListDetails {
    return new CMListDetails(
      json['id'],
      json['name'],
      json['tags'] ? json['tags'].map((t: any) => CMListTag.fromJson(t)) : [],
      json['customers'] ? json['customers'].map((c: any) => Customer.fromJson(c)) : [],
      json['subscribers'] ? json['subscribers'].map((s: any) => CMListSubscriber.fromJson(s)) : [],
    )
  }
}

export type contractStage = 'Concept'|'Actief'|'Afgerond'|'Archief'

export class Contract {
  constructor(
    public id: string,
    public name: string,
    public tenantId: string,
    public customerId: string,
    public hourBudget: number,
    public status: contractStage,
    public timeCategoryId: string,
    public repeatNMonths: number,
    public tenant: Tenant,
    public customer: Customer,
    public timeCategory: TicketTimeCategory,
    public ticketTime: TicketTime[],
    public createdAt: Date,
    public updatedAt: Date,
    public startAt: Date,
    public endAt: Date,
  ) {}

  public static fromJson(json: any): Contract {
    return new Contract(
      json['id'],
      json['name'],
      json['tenant_id'],
      json['customer_id'],
      json['hour_budget'],
      json['status'],
      json['time_category_id'],
      json['repeat_n_months'],
      Tenant.fromJson(json['tenant']),
      Customer.fromJson(json['customer']),
      TicketTimeCategory.fromJson(json['ticket_time_category']),
      json['ticket_time'] ? json['ticket_time'].map((t: any) => TicketTime.fromJson(t)) : [],
      new Date(json['created_at']),
      new Date(json['updated_at']),
      new Date(json['start_at']),
      new Date(json['end_at']),
    )
  }
}

export class TicketTemplate {
  constructor(
    public id: string,
    public name: string,
    public type: string,
    public schedule: string|null,
    public subject: string,
    public description: string,
    public priorityId: string,
    public customerId: string|null,
    public teamId: string|null,
    public tasks: string[],
    public createdAt: Date,
    public updatedAt: Date,
  ) {}

  public static fromJson(json: any): TicketTemplate {
    return new TicketTemplate(
      json['id'],
      json['name'],
      json['type'],
      json['schedule'] ?? null,
      json['subject'],
      json['description'],
      json['priority_id'],
      json['customer_id'] ?? null,
      json['team_id'] ?? null,
      json['tasks'] ?? [],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}