import React, {FC, useCallback, useMemo, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Select} from "../components/form/Select";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Customer, TicketPriority, TicketTemplate, User} from "../api/dto";
import {Autocomplete} from "../components/form/Autocomplete";
import {InputErrors} from "../components/form/InputErrors";
import {usePersistentState} from "../util/usePersistentState";
import {useApi, useFetchedResource} from "../api/APIContext";
import {useTenant} from "../tenant/TenantContext";

export const AddTicketModal: FC<{priorities: TicketPriority[], ticketTemplates: TicketTemplate[], customers: Customer[], users: User[]}> = (props) => {
  const {addTicket} = useApiCall()
  const {currentTenant} = useApi()
  const refactoredUser = props.users.map((user) => [user.id, user.name])
  const refactoredPriorities = props.priorities.map((item) => [item.id, item.priority])
  const refactoredCustomers = props.customers.map((item) => [item.id, item.name])
  const [title, setTitle] = usePersistentState<string>('add_ticket_title', '')
  const [description, setDescription] = usePersistentState<string>('add_ticket_description', '')
  const [priority, setPriority] = useState(props.priorities[0].id)
  const [customer, setCustomer] = useState('')
  const [type, setType] = useState<'incident'|'service_request'|'change'|'problem'>('incident') // not being used for now
  const [tasks, setTasks] = useState<string[]>([])
  const [reporter, setReporter] = useState<string>('')
  // const [team, setTeam] = useState('') // not being used for now
  const [errors, setErrors] = useState<ErrorBag>({})

  const ticketTemplateMap = [
    ['-', 'Kies template om ticket aan te vullen'],
    ...props.ticketTemplates.filter(t => t.customerId === customer || t.customerId === null).filter(t => t.schedule === null).map((item) => [item.id, item.name])
  ]

  let reporterMap = useMemo(() => {
    let reporterEntries = props.users.map((item) => [item.id, '(Intern) ' + item.name]);
    const selectedCustomer = props.customers.find(c => c.id === customer);
    if (selectedCustomer !== undefined && selectedCustomer.users.length > 0) {
      reporterEntries = [...selectedCustomer.users.map((item) => [item.id, item.name]), ...reporterEntries]
    }
    return Object.fromEntries(reporterEntries);
  }, [props.users, props.customers, customer]);

  const reload = useRefresh()

  const save = useCallback( async () => {
    const promise = addTicket({
      subject: title,
      description: description,
      priority_uuid: priority,
      customer_uuid: customer,
      type: type,
      tasks: tasks,
      reporter_uuid: reporter ?? null
      }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    setDescription('')
    setTitle('')
    await promise
    reload()
  }, [title, description, priority, customer, tasks])

  const applyTemplate = (templateId: string) => {
    const template = props.ticketTemplates.find(t => t.id === templateId)
    if (!template) {
      return
    }
    setType(template.type as 'change'|'service_request')
    setTitle(template.subject)
    setPriority(template.priorityId)
    setDescription(template.description)
    setTasks(template.tasks)
  }

  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1"}>
      <div className={'grid grid-cols-4 gap-4'}>
        <Autocomplete label={"Klant"} options={Object.fromEntries(refactoredCustomers)} value={customer}
                      onChange={(v) => setCustomer(v)}/>
        {currentTenant?.permissions.find(p => p.startsWith('ticket:edit')) &&
          <Autocomplete label={'Reporter'} options={reporterMap} value={reporter} onChange={(v)  => setReporter(v)}/>
        }
        {ticketTemplateMap.length > 1 && <Autocomplete label={"Template"} options={Object.fromEntries(ticketTemplateMap)} value={'-'}
                       onChange={(v) => applyTemplate(v)}/>}
      </div>
      <div className={'grid grid-cols-1 lg:grid-cols-2 gap-3 w-full'}>
        <button type={'button'} onClick={() => setType('incident')}
                className={`rounded px-4 py-3 flex flex-col items-start text-left ${type === 'incident' ? 'text-brand-900 dark:text-brand-100 border border-brand-700 dark:border-brand-700 bg-brand-50 dark:bg-brand-900' : 'text-slate-600 dark:text-zinc-300 border border-slate-200 dark:border-zinc-600'}`}>
          <h2 className={'text-black dark:text-white'}>Incident</h2>
          <p className={'mt-1 text-xs'}>Onderbreking van dienst of vermindering van kwaliteit</p>
        </button>
        <button type={'button'} onClick={() => setType('change')}
                className={`rounded px-4 py-3 flex flex-col items-start text-left ${type === 'change' ? 'text-brand-900 dark:text-brand-100 border border-brand-700 dark:border-brand-700 bg-brand-50 dark:bg-brand-900' : 'text-slate-600 dark:text-zinc-300 border border-slate-200 dark:border-zinc-600'}`}>
          <h2 className={'text-black dark:text-white'}>Change</h2>
          <p className={'mt-1 text-xs'}>Wijziging in infrastructuur of diensten</p>
        </button>
        {currentTenant?.permissions.find(p => p.startsWith('ticket:edit')) &&
          <button type={'button'} onClick={() => setType('problem')}
                className={`rounded px-4 py-3 flex flex-col items-start text-left ${type === 'problem' ? 'text-brand-900 dark:text-brand-100 border border-brand-700 dark:border-brand-700 bg-brand-50 dark:bg-brand-900' : 'text-slate-600 dark:text-zinc-300 border border-slate-200 dark:border-zinc-600'}`}>
          <h2 className={'text-black dark:text-white'}>Problem</h2>
          <p className={'mt-1 text-xs'}>Onderliggende oorzaak van één of meerdere incidenten</p>
        </button>
        }
        <button type={'button'} onClick={() => setType('service_request')}
                className={`rounded px-4 py-3 flex flex-col items-start text-left ${type === 'service_request' ? 'text-brand-900 dark:text-brand-100 border border-brand-700 dark:border-brand-700 bg-brand-50 dark:bg-brand-900' : 'text-slate-600 dark:text-zinc-300 border border-slate-200 dark:border-zinc-600'}`}>
          <h2 className={'text-black dark:text-white'}>Service Request</h2>
          <p className={'mt-1 text-xs'}>Verzoek voor standaarddienst of toegestane informatie</p>
        </button>
      </div>
      <div className={'grid grid-cols-1 lg:grid-cols-4 gap-4'}>
        <div className={""}>
          <Select label={"Prioriteit"} options={Object.fromEntries(refactoredPriorities)} value={priority}
                  onChange={(v) => setPriority(v)}/>
        </div>
        <div className={"items-center col-span-3"}>
          <Input label={"Titel"} type={"text"} value={title} onChange={(v) => setTitle(v)}/>
          <InputErrors errors={errors} field={'subject'}/>
        </div>
      </div>
      <div className={"items-center"}>
        <Input label={"Beschrijving"} type={"textarea"} value={description} onChange={(v) => setDescription(v)}/>
        <InputErrors errors={errors} field={'description'}/>
      </div>
      <InputErrors errors={errors} field={'customer_uuid'}/>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}